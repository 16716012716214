import React, { useState, useEffect} from "react";
import { Breadcrumb, Table, Button, Divider} from "antd";
import requester from "../../utils/requester";
import { Link } from "react-router-dom";

const columns = [
    {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        // eslint-disable-next-line
        width: 50,
        render: (text) => <Link to={`/administrator/${text}`}>{text}</Link>
    },
    {
        title: 'name',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: 'email',
        dataIndex: 'email',
        key: 'email',
    },
    {
        title: 'phone',
        dataIndex: 'phone',
        key: 'phone',
    },
    {
        title: 'status',
        dataIndex: 'status',
        key: 'status',
    }
];



const  Administrators = () => {

    const loadData = () => {
        requester.get('/administrators').then((response) => {
            if (response.status === 200) {
                setData(response.data.data.data)
            }
        })
    }

    const [data, setData] = useState([])

    useEffect(() => {
        console.log("here is administrators")
        loadData()
    }, []) 

    return (
        <div>
            <Breadcrumb style={{ margin: '16px 0' }}>
                <Breadcrumb.Item>管理员列表</Breadcrumb.Item>
            </Breadcrumb>
            <Link to="/administrator"><Button type="primary">添加管理员</Button></Link>
            <Divider>列表</Divider>
            <Table rowKey={(record) => record.id} columns={columns} dataSource={data} />
        </div>
    )
}

export default Administrators