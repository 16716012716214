import React from 'react'
import { Breadcrumb, Table, Button, Divider} from 'antd';
import requester from '../../utils/requester'
import querystring from 'querystring';
import { Link } from 'react-router-dom';

const columns = [
    {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        // eslint-disable-next-line
        width: 50,
        render: (text, record) => <Link to={`/confighub/${record.name}/${text}/`}>{text}</Link>
    },
    {
        title: 'App',
        dataIndex: 'name',
        key: 'id',
        width: 120,
    },
    {
        title: '备注',
        dataIndex: 'remark',
        key: 'remark',
        ellipsis: true
    },
    {
        title: '添加时间',
        dataIndex: 'atime',
        key: 'atime',
        width: 200,
    },
    {
        title: '操作',
        dataIndex: 'id',
        render: (text, record) => <Link to={`/confighub/${record.name}/${text}/configs`}>配置</Link>,
        width: 200,
    }
];


class Apps extends React.Component {
    constructor (props) {
        super(props)

        this.state = {
            data: [],
            pagination: {
                defaultCurrent: 1,
                defaultPageSize: 20,
                pageSize: 20,
                page:1,
                total: 0,
                current: 1,
                onChange: this.onPageChange,
            },
            page: 1
        }
    }

    onPageChange = (page, pageSize) => {
        console.log(pageSize)
        this.loadData({page:page, pageSize:pageSize})
    }

    componentDidMount = () => {
        this.loadData({page: 1, pageSize: 20});
    }

    loadData = (params) => {
        requester.get('apps?' + querystring.stringify(params)).then( (response) => {
            if (response.status === 200) {
                let pagination = this.state.pagination
                pagination.page = response.data.data.current_page
                pagination.current = response.data.data.current_page
                pagination.total = response.data.data.total

                this.setState({
                    data: response.data.data.data,
                    pagination: pagination
                })
            }
        }).catch(function (error) {
            console.log(error)
        })
    }
    
    render () {
        return (
            <div>
                <Breadcrumb style={{ margin: '16px 0' }}>
                    <Breadcrumb.Item>App列表</Breadcrumb.Item>
                </Breadcrumb>
                <Link to="/confighub/app"><Button type="primary">添加App</Button></Link>
                <Divider>列表</Divider>
                <Table rowKey={(record) => record.id} columns={columns} dataSource={this.state.data} pagination={this.state.pagination} />
            </div>  
        )
    }
}

export default Apps;