import React, {useEffect} from "react";
import { Form, Input, Button, Breadcrumb, Switch} from 'antd';
import requester from "../../utils/requester";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router";


const EditAdministrator = () => {

    const [form] = Form.useForm();
    const history = useHistory()
    const {id} = useParams()

    useEffect(() => {
        if (id) {
            getAdministrator(id)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const onFinish = (values) => {
        console.log(values)
        if (id) {
            requester.put("/administrator/" + id, values).then((response) => {
                if (response.status === 200) {
                    alert("修改成功")
                }
            })
        } else {
            requester.post("/administrator", values).then((response) => {
                if (response.status === 200) {
                    alert("新增成功")
                    history.push('/administrators')
                }
            })
        }
    }

    const getAdministrator = (id) => {
        requester.get("administrator/" + id).then((response) => {
            if (response.status === 200) {
                form.setFieldsValue(response.data.data)
            }
        })
    }

    return (
        <div>
            <Breadcrumb style={{ margin: '16px 0' }}>
                <Breadcrumb.Item>添加管理员</Breadcrumb.Item>
            </Breadcrumb>

            <Form
                form={form}
                layout="vertical"
                defaultValue={{}}
                onFinish={onFinish}
                autoComplete="off"
                style={{ maxWidth: 1000 }}
            >
                <Form.Item label="Name" name="name" required tooltip="This is a required field">
                    <Input placeholder="name" />
                </Form.Item>
                <Form.Item label="Email" name="email" required tooltip="This is a required field">
                    <Input placeholder="email"  />
                </Form.Item>
                <Form.Item label="Phone" name="phone" required tooltip="This is a required field">
                    <Input placeholder="phone"/>
                </Form.Item>
                <Form.Item label="Super Administrator" name="is_super" required tooltip="设置是否为超级管理员" valuePropName="checked">
                        <Switch checkedChildren="是" unCheckedChildren="否" />
                </Form.Item>
                <Form.Item label="Password" name="password"  tooltip="不修改密码时不要填写" >
                    <Input.Password placeholder="Password" />
                </Form.Item>
                <Form.Item label="Password again" name="password_confirm" tooltip="不修改密码时不要填写">
                    <Input.Password placeholder="Password confirm" />
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit">Submit</Button>
                </Form.Item>
            </Form>
        </div>
    )
}

export default EditAdministrator