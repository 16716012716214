import React, { useState, useEffect } from 'react'
import {Breadcrumb, Table, Form, Select, Input, Button, Tag, Divider} from 'antd';
import requester from '../../utils/requester'
import querystring from 'querystring';
import { Link } from 'react-router-dom';

const columns = [
    {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        // eslint-disable-next-line
        width: 50,
        render: (text) => <Link to={`/feedback/${text}`}>{text}</Link>
    },
    {
        title: 'App',
        dataIndex: 'app_name',
        key: 'id',
    },
    {
        title: 'user_id',
        dataIndex: 'user_id',
        key: 'id',
    },
    {
        title: 'content',
        dataIndex: 'content',
        key: 'id',
        width: 500
    },
    {
        title: 'App版本',
        dataIndex: 'app_version',
        key: 'id',
    },
    // {
    //     title: '系统版本',
    //     dataIndex: 'sys_version',
    //     key: 'id',
    // },
    // {
    //     title: '设备型号',
    //     dataIndex: 'phone_model',
    //     key: 'id',
    // },
    {
        title: '添加时间',
        dataIndex: 'atime',
        key: 'id',
        with: 50,
    },
    {
        title: '状态',
        dataIndex: 'status',
        key: 'id',
        with: 30,
        render: (text) => {
            let tag = null
            switch(text) {
                case 0: 
                    tag = <Tag color="magenta">未处理</Tag>
                    break
                case 1:
                    tag = <Tag color="green">已处理</Tag>
                    break
            }

            return tag
        }
    },
];


const Feedback = () => {
    const [data, setData] = useState([])
    const [pagination, setPagination] = useState({
        defaultCurrent: 1,
        defaultPageSize: 20,
        pageSize: 20,
        page:1,
        total: 0,
        current: 1,
    })
    const [status, setStatus] = useState(0)
    const [form] = Form.useForm();

    useEffect(() => {
        loadData({status: 0, page: 1, pageSize: 20})
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const loadData = (params) => {
        console.log("search values:", params)
        requester.get('feedback?' + querystring.stringify(params)).then( (response) => {
            if (response.status === 200) {
                pagination.page = response.data.data.current_page
                pagination.current = response.data.data.current_page
                pagination.total = response.data.data.total
                setPagination(pagination)
                setData(response.data.data.data)
            }
        }).catch(function (error) {
            console.log(error)
        })
    }

    const onPageChange = (page, pageSize) => {
        pagination.pageSize = pageSize
        setPagination(pagination)
        loadData({status: status, page:page, pageSize:pageSize})
    }

    const onSelectChange = (value) => {
        setStatus(value)
        loadData({status: value, page: 1, pageSize: 20})
    }

    const onShowSizeChange = (current, pageSize) => {
        pagination.pageSize = pageSize
        setPagination(pagination)
        loadData({status: status, page: current, pageSize: pagination.pageSize})
    }
    const search = (values) => {
        pagination.page = 1;
        setPagination(pagination)
        loadData({page: 1, pageSize: pagination.pageSize, ...values})
    }

    return (
        <div>
            <Breadcrumb style={{ margin: '16px 0' }}>
                <Breadcrumb.Item>用户反馈</Breadcrumb.Item>
            </Breadcrumb>
            <Form form={form} layout='inline' onFinish={search}>
                <Form.Item label="状态" name='status'>
                    <Select 
                    style={{
                        width: 120,
                      }}
                    defaultValue={status}
                    onChange={onSelectChange}
                    options={[
                        {
                            value: null,
                            label: '全部'
                        },
                        {
                            value: 1,
                            label: '已回复',
                        },
                        {
                            label: '未处理',
                            value: 0,
                        }
                    ]}/>
                </Form.Item>
                <Form.Item label="邮箱" name='connection'>
                    <Input />
                </Form.Item>
                <Form.Item label="用户ID" name='user_id'>
                    <Input />
                </Form.Item>
                <Form.Item >
                    <Button type="primary" htmlType='submit'>Submit</Button>
                </Form.Item>
            </Form>
            <Divider>反馈列表</Divider>
            <Table rowKey={(record) => record.id} columns={columns} dataSource={data} pagination={{...pagination, showSizeChanger: true, onChange: onPageChange, onShowSizeChange: onShowSizeChange}}/>
        </div> 
    )
}


export default Feedback;