import axios from "axios";

const config = {
    baseURL: process.env.REACT_APP_BASE_URL,
    timeout: 30000,
    headers: {
        //
    },
}

const requester = axios.create(config);

// 请求的前置操作
requester.interceptors.request.use(function (config) {

    var jwt = localStorage.getItem('jwt')

    if (typeof jwt !== 'undefined') {
        config.headers.Authorization = "Bearer " + jwt
    }
    
    return config;
}, function (error) {
    console.log("request error:", error.response)
    return Promise.reject(error);
})

requester.interceptors.response.use(function (response) {
    if (response.status === 401) {
        console.log("error:" + response.status)
    } 

    return response
}, function(error) { 
    console.log(error)
    if (error.response && error.response.status === 401) {
        window.location.href ="/login"
    }
    
    return Promise.reject(error);
    
})


export default requester;