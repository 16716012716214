import React, { useState, useEffect } from "react";
import { Form, Input, Button, Breadcrumb, } from 'antd';
import { useParams } from "react-router";
import requester from "../../utils/requester";

const ViewVersion = () => {
    const [form] = Form.useForm();
    const [version, setVersion] = useState(null)
    const [valueChanged, setValueChanged] = useState(0)

    // eslint-disable-next-line
    const { id } = useParams()

    useEffect(() => {
        // eslint-disable-next-line
        requester.get('/version/' + id).then((response) => {
            if (response.status === 200) {
                formatVersion(response.data.data)
            }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useState(() => {

    }, [valueChanged])

    const onRequiredTypeChange = () => {

    };
    
    const formatVersion = (version) => {
        version.english = version.content.english
        version.spanish = version.content.spanish
        version.simplifiedChinese = version.content.simplifiedChinese
        version.traditionalChinese = version.content.traditionalChinese
        setVersion(version)
    }

    const onValueChange = (e) => {
        version[e.target.name] = e.target.value
        setVersion(version)
        setValueChanged(!valueChanged)
    }

    const onFinish = (values) => {
        requester.put("/version/" + id, version).then((response) => {
            if (response.status === 200) {
                alert("保存成功")
                formatVersion(response.data.data)
                setValueChanged(!valueChanged)
            }
        })
    }

    return (
        <div>
            <Breadcrumb style={{ margin: '16px 0' }}>
                <Breadcrumb.Item>管理员详情</Breadcrumb.Item>
            </Breadcrumb>

            <Form
                form={form}
                layout="vertical"
                onValuesChange={onRequiredTypeChange}
                onFinish={onFinish}
            >
                <Form.Item label="App Name" required tooltip="App名称, notehot|rmeeting">
                    <Input name="app_name" placeholder="App Name" value={version ? version.app_name : ''}/>
                </Form.Item>
                <Form.Item label="version" required tooltip="This is a required field">
                    <Input name="version" placeholder="version" value={version ? version.version : ''} onChange={onValueChange}/>
                </Form.Item>
                <Form.Item label="version Code" required tooltip="This is a required field">
                    <Input name="version_code" placeholder="version code" value={version ? version.version_code : ''} onChange={onValueChange}/>
                </Form.Item>
                <Form.Item label="english" required tooltip="This is a required field">
                    <Input.TextArea name="english" placeholder="english" value={version ? version.english : ''} onChange={onValueChange}/>
                </Form.Item>
                <Form.Item label="spanish" required tooltip="This is a required field">
                    <Input.TextArea name="spanish" placeholder="spanish" value={version ? version.spanish : ''} onChange={onValueChange}/>
                </Form.Item>
                <Form.Item label="simplifiedChinese" required tooltip="This is a required field">
                    <Input.TextArea name="simplifiedChinese" placeholder="simplifiedChinese" value={version ? version.simplifiedChinese : ''} onChange={onValueChange}/>
                </Form.Item>
                <Form.Item label="traditionalChinese" required tooltip="This is a required field">
                    <Input.TextArea name="traditionalChinese" placeholder="traditionalChinese" value={version ? version.traditionalChinese : ''} onChange={onValueChange}/>
                </Form.Item>
                <Form.Item label="platform" required tooltip="This is a required field">
                    <Input name="platform" placeholder="platform" value={version ? version.platform : ''} onChange={onValueChange}/>
                </Form.Item><Form.Item label="is_show" required tooltip="This is a required field">
                    <Input name="is_show" placeholder="is_show" value={version ? version.is_show : ''} onChange={onValueChange}/>
                </Form.Item>
                <Form.Item label="Force Update" required tooltip="This is a required field">
                    <Input placeholder="is_force" name="is_force" value={version ? version.is_force : ''} onChange={onValueChange}/>
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit">Save</Button>
                </Form.Item>
            </Form>
        </div>
    )
}

export default ViewVersion