import { JSONEditor } from "vanilla-jsoneditor";
import { useEffect, useRef } from "react";

const VJsonEditor = (props) => {
    const refContainer = useRef(null)
    const refEditor = useRef(null)

    useEffect(() => {
        refEditor.current = new JSONEditor({
            target: refContainer.current,
            props:{
                mode: 'text'
            },
        });

        return () => {
            // destroy editor
            if (refEditor.current) {
                refEditor.current.destroy();
                refEditor.current = null;
            }
        }
    }, [])

    useEffect(() => {
        if (refContainer.current) {
            refEditor.current.updateProps(props);
        }
    }, [props]) 

    return (<div className="vanilla-jsoneditor-react" ref={refContainer}></div>)
}

export default VJsonEditor