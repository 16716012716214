import React, {useEffect} from "react";
import requester from "../../utils/requester";
import { useHistory } from "react-router";
import { Form, Input, Button } from 'antd';
import "./login.css"


const Login = () => {
    useEffect(() => {
    }) 

    let history = useHistory();

    const onFinish = (values) => {
        requester.post('/login', values).then(function (response) {
            if (typeof response != "undefined" && response.status === 200) {
                console.log(response)
                var jwt = response.data.data['jwt']
                localStorage.setItem('jwt', jwt);
                history.push("/feedback")
                // window.location.href = window.location.origin + '';
            }
        })
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <div className="content login-content">
        <Form
            name="basic"
            labelCol={{
                span: 8,
            }}
            wrapperCol={{
                span: 16,
            }}
            initialValues={{
                remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
        >
            <Form.Item
                label="Email"
                name="email"
                rules={[
                    {
                        required: true,
                        message: 'Please input your username!',
                    },
                ]}
            >
                <Input />
            </Form.Item>

            <Form.Item
                label="Password"
                name="password"
                rules={[
                    {
                        required: true,
                        message: 'Please input your password!',
                    },
                ]}
            >
                <Input.Password />
            </Form.Item>

            {/* <Form.Item
                name="remember"
                valuePropName="checked"
                wrapperCol={{
                    offset: 8,
                    span: 16,
                }}
            >
                <Checkbox>Remember me</Checkbox>
            </Form.Item> */}

            <Form.Item
                wrapperCol={{
                    offset: 8,
                    span: 16,
                }}
            >
                <Button type="primary" htmlType="submit">
                    进入
                </Button>
            </Form.Item>
        </Form>
        </div>
    );
}

export default Login